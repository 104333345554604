<template>
  <div>

    <HeaderMain />

    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container">
        <div class="w-layout-grid grid-2-colum">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left">
            <div class="w-layout-vflex tittle">
              <div class="w-layout-vflex heading-title">
                <h1 class="heading">Займы <br>на карту 0%</h1>
                <h2 class="heading h2">моментальное одобрение</h2>
              </div>
              <div class="w-layout-hflex flex-good">
                <div data-w-id="5f41a92a-c9c1-4c5c-9e68-2b2ddd3f4fd7" class="w-layout-hflex text-icon-item"><img src="../../../../../public/images/bolt.svg" loading="lazy" alt="" class="icon">
                  <div class="text-classic bold">Автоматическая проверка</div>
                </div>
                <div data-w-id="e96db9c4-1c5e-06c0-21ad-f688906f44e9" class="w-layout-hflex text-icon-item"><img src="../../../../../public/images/compliance-document.svg" loading="lazy" alt="" class="icon">
                  <div class="text-classic bold">Без справок и звонков</div>
                </div>
                <div data-w-id="e96db9c4-1c5e-06c0-21ad-f688906f44ed" class="w-layout-hflex text-icon-item"><img src="../../../../../public/images/shield-check-1.svg" loading="lazy" alt="" class="icon">
                  <div class="text-classic bold">Безопасность</div>
                </div>
              </div>
            </div>
          </div>
          <div id="w-node-_8a817dec-33d8-ba64-0999-5e0ddc2f3a91-8870dddc" class="grid-colum right">
            <div class="calculators">
              <div class="flex-calculate">
                <div class="timer-title">
                  <div class="w-layout-hflex timer-flex"><img src="../../../../../public/images/Outline.svg" loading="lazy" alt="" class="image">
                    <div class="text-mini white time">Деньги у вас уже в</div>
                  </div>
                  <div class="timelabel">
                    <div class="time-text">{{ futureTime }}</div>
                  </div>
                </div>
                <div class="calculate-block one">
                  <div class="sum-top">
                    <div class="calculate-namber-box">
                      <div class="text-mini">5 000 ₽</div>
                      <div class="text-mini">30 000 ₽</div>
                    </div>
                    <div class="sum-result"><span id="calculator_sum" class="sum">10 000</span> <span class="rub">₽</span></div>
                  </div>
                  <div class="wrapper-block">
                    <div class="range-slaider w-embed">
                      <div class="calculator_bottom-slider slider-styled" id="slider-sum"></div>
                    </div>
                  </div>
                </div>
                <div class="calculate-block right">
                  <div class="sum-top">
                    <div class="calculate-namber-box">
                      <div class="text-mini">91 дней</div>
                      <div class="text-mini">365 дней</div>
                    </div>
                    <div class="sum-result"><span id="calculator_date" class="day-namber">91</span> дней</div>
                  </div>
                  <div id="w-node-_94cd84f4-706f-fb9f-9827-6f4f6393e98b-8870dddc" class="wrapper-block">
                    <div class="range-slaider w-embed">
                      <div class="calculator_bottom-slider" id="slider-period"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-layout-hflex result-box">
                <div class="alculator-results-block left">
                  <div class="text-mini black">Вы возвращаете:</div>
                  <div class="result-sum">
                    <div class="text-mini sum red"><span id="sale-price" class="sale-prices">36 300</span> ₽</div>
                    <div class="text-mini sum green"><span id="price" class="price">30 000</span> ₽</div>
                  </div>
                </div>
                <div class="alculator-results-block">
                  <div class="text-mini black">Дата возврата</div>
                  <div id="date" class="text-mini sum">24.10.2023</div>
                </div>
              </div>
              <div class="w-layout-hflex flex-item-best">
                <a id="openLabel" @click="clickOpenLabel1" class="button-bust w-button">Оформить заявку</a>
                <link rel="prerender" @click="clickOpenLabel1">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="section">
      <figure id="Kak-rabotaet" class="container w-container">
        <div class="w-layout-grid grd">
          <div id="w-node-_96ce52b2-843a-c0e0-36df-5b1cff9bd9e5-8870dddc" class="item padding-right">
            <h2 class="heading h2">Как работает</h2>
            <div class="text-classic widht">Заполните онлайн-заявку на получение займа, указав необходимую сумму и срок займа. После подтверждения средства зачисляются на вашу банковскую карту</div>
          </div>
          <div id="w-node-_54653a8c-d621-38aa-570d-bb7662ea6fbf-8870dddc" class="item _2">
            <div class="text-mini semibold">Шаги</div>
            <div class="w-layout-hflex item-row">
              <div class="w-layout-vflex item-info">
                <div class="step-text">1</div>
                <div class="text-classic semibold">Подайте заявку</div>
                <div class="text-classic gray">Займет не более 10 минут</div>
              </div>
              <div class="w-layout-vflex item-info">
                <div class="step-text">2</div>
                <div class="text-classic semibold">Подтверждение</div>
                <div class="text-classic gray">Дадим ответ в течение <br>5 минут</div>
              </div>
              <div class="w-layout-vflex item-info">
                <div class="step-text">3</div>
                <div class="text-classic semibold">Получите деньги</div>
                <div class="text-classic gray">На банковскую карту онлайн</div>
              </div>
            </div>
          </div>
        </div>
      </figure>
      <section id="Pochemu" class="container w-container">
        <div class="w-layout-grid grd">
          <div id="w-node-ab6d8353-038f-d5eb-c2b5-ebdaefa956eb-8870dddc" class="item">
            <h2 class="heading h2">Почему мы</h2>
            <div class="text-classic widht">Заём онлайн — это удобная альтернатива кредитованию. Мы предлагаем микрозаймы от 5 000 до 30 000 рублей на срок от 91 до 365 дней.</div>
          </div>
          <div id="w-node-ab6d8353-038f-d5eb-c2b5-ebdaefa956f0-8870dddc" class="item _2">
            <div class="text-mini semibold">Проценты</div>
            <div class="w-layout-hflex item-row">
              <div class="w-layout-vflex item-info">
                <div class="text-classic">Процентная ставка — от 0% до 0.1% в день. Заём одобряется мгновенно. Деньги зачисляются в течение нескольких минут.</div>
              </div>
            </div>
          </div>
          <div id="w-node-_180e09eb-dcd8-7cff-67d3-a4e0756007ee-8870dddc" class="item _2">
            <div class="text-mini semibold">Способы получения</div>
            <div class="w-layout-hflex item-row">
              <div class="w-layout-vflex item-info row"><img src="../../../../../public/images/visa.svg" loading="lazy" alt="" class="logo-1"><img src="../../../../../public/images/mir.svg" loading="lazy" alt="" class="logo-2"><img src="images/mastercard.svg" loading="lazy" alt="" class="logo-3"></div>
            </div>
          </div>
        </div>
      </section>
      <div id="Faq" class="container last w-container">
        <div class="w-layout-grid grd">
          <div id="w-node-cb504ef1-aacd-ca82-8e11-c575d4e51a17-8870dddc" class="item">
            <h2 class="heading h2">Частые вопросы</h2>
            <div class="text-classic widht">Неожиданные расходы могут внезапно возникнуть в любой момент. Именно для таких ситуаций и созданы наши займы - быстрые, удобные и доступные всем. Независимо от того, нужен ли вам кредит на покупку техники, оплату счетов или просто денег до зарплаты - мы всегда готовы помочь. У нас вы найдете выгодные условия и гибкие условия погашения<br>‍<br>Не откладывайте свои финансовые проблемы на потом, обращайтесь к нам и получите деньги прямо сейчас!</div>
          </div>
          <div id="w-node-ecd4baea-bc95-482a-4934-27182774b207-8870dddc" class="item _2">
            <div class="text-mini-2 semibold">Ответы</div>
            <div class="w-layout-hflex item-row-2">
              <div class="w-layout-vflex item-info">
                <div class="text-classic-2 semibold">Возраст от 18 до 70 лет</div>
              </div>
              <div class="w-layout-vflex item-info">
                <div class="text-classic-2 semibold">Нужен только паспорт</div>
              </div>
              <div class="w-layout-vflex item-info">
                <div class="text-classic-2 semibold">Гражданство РФ</div>
              </div>
            </div>
            <div class="faq-block">
              <div fs-accordion-initial="none" fs-accordion-element="group" class="fs_accordion-2_component">
                <div class="fs_accordion-2_embed w-embed w-script"><!--  [Finsweet Attributes] Accordion  -->
                  <!--                <script>(()=>{var t="https://cdn.jsdelivr.net/npm/@finsweet/attributes-accordion@1/accordion.js",e=document.querySelector(`script[src="${t}"]`);e||(e=document.createElement("script"),e.async=!0,e.src=t,document.head.append(e));})();</script>-->
                </div>
                <div fs-accordion-element="accordion" class="fs_accordion-2_item-2 one-last">
                  <div id="accordion-2-header-1" tabindex="0" role="button" aria-controls="accordion-2-content-1" aria-expanded="false" fs-accordion-element="trigger" class="fs_accordion-2_header-2">
                    <div class="text-cont-2">Как получить деньги?</div>
                    <div fs-accordion-element="arrow" class="fs_accordion-2_arrow-wrapper is-active-accordion"><img loading="lazy" src="../../../../../public/images/Check.svg" alt="" class="fs_accordion-2_icon-2"></div>
                  </div>
                  <div id="accordion-2-content-1" aria-labelledby="accordion-2-header-1" fs-accordion-element="content" class="fs_accordion-2_content">
                    <div class="fs_accordion-2_body">
                      <p class="text-standart-2">Вы можете получить займ на карту, наличными или на банковский счет.</p>
                    </div>
                  </div>
                </div>
                <div fs-accordion-element="accordion" class="fs_accordion-2_item-2">
                  <div id="accordion-2-header-2" tabindex="0" role="button" aria-controls="accordion-2-content-2" aria-expanded="false" fs-accordion-element="trigger" class="fs_accordion-2_header-2">
                    <div class="text-cont-2">Как погасить полученный займ?</div>
                    <div fs-accordion-element="arrow" class="fs_accordion-2_arrow-wrapper"><img loading="lazy" src="../../../../../public/images/Check.svg" alt="" class="fs_accordion-2_icon-2"></div>
                  </div>
                  <div id="accordion-2-content-2" aria-labelledby="accordion-2-header-2" fs-accordion-element="content" class="fs_accordion-2_content">
                    <div class="fs_accordion-2_body">
                      <p class="text-standart-2">Вы можете погасить займ наличными деньгами, платежом с банковской карты, банковским переводом.</p>
                    </div>
                  </div>
                </div>
                <div fs-accordion-element="accordion" class="fs_accordion-2_item-2">
                  <div id="accordion-2-header-3" tabindex="0" role="button" aria-controls="accordion-2-content-3" aria-expanded="false" fs-accordion-element="trigger" class="fs_accordion-2_header-2">
                    <div class="text-cont-2">Как долго обрабатывается заявка?</div>
                    <div fs-accordion-element="arrow" class="fs_accordion-2_arrow-wrapper"><img loading="lazy" src="../../../../../public/images/Check.svg" alt="" class="fs_accordion-2_icon-2"></div>
                  </div>
                  <div id="accordion-2-content-3" aria-labelledby="accordion-2-header-3" fs-accordion-element="content" class="fs_accordion-2_content">
                    <div class="fs_accordion-2_body">
                      <p class="text-standart-2">Заявка на займ обрабатывается от нескольких секунд до 15 минут в самых редких случаях.</p>
                    </div>
                  </div>
                </div>
                <div fs-accordion-element="accordion" class="fs_accordion-2_item-2">
                  <div id="accordion-2-header-3" tabindex="0" role="button" aria-controls="accordion-2-content-3" aria-expanded="false" fs-accordion-element="trigger" class="fs_accordion-2_header-2">
                    <div class="text-cont-2 faq"><strong class="text-cont-2">Возможно ли получить займ, имея просрочки по кредитам?</strong></div>
                    <div fs-accordion-element="arrow" class="fs_accordion-2_arrow-wrapper"><img loading="lazy" src="../../../../../public/images/Check.svg" alt="" class="fs_accordion-2_icon-2"></div>
                  </div>
                  <div id="accordion-2-content-3" aria-labelledby="accordion-2-header-3" fs-accordion-element="content" class="fs_accordion-2_content">
                    <div class="fs_accordion-2_body">
                      <p class="text-standart-2">Да, наличие просрочек не влияет на решение о выдаче займа. Тем не менее, мы проверяем кредитную историю заёмщика и размер подтверждённого дохода.</p>
                    </div>
                  </div>
                </div>
                <div fs-accordion-element="accordion" class="fs_accordion-2_item-2">
                  <div id="accordion-2-header-3" tabindex="0" role="button" aria-controls="accordion-2-content-3" aria-expanded="false" fs-accordion-element="trigger" class="fs_accordion-2_header-2">
                    <div class="text-cont-2 faq"><strong class="text-cont-2">Как оплатить займ без комиссии?</strong></div>
                    <div fs-accordion-element="arrow" class="fs_accordion-2_arrow-wrapper"><img loading="lazy" src="../../../../../public/images/Check.svg" alt="" class="fs_accordion-2_icon-2"></div>
                  </div>
                  <div id="accordion-2-content-3" aria-labelledby="accordion-2-header-3" fs-accordion-element="content" class="fs_accordion-2_content">
                    <div class="fs_accordion-2_body">
                      <p class="text-standart-2">К сожалению, оплатить займ без комиссии не получится, так как комиссию взымает банк заёмщика. Размер комиссии составит не более 0.1%.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderMain } from '/src/app/shared/components';
import loadjs from 'loadjs';
import store from '@/app/app-state';

export default {
  name: 'Main',
  data() {
    return {
      slider: null,
      slider2: null,
      currentTime: new Date()
    };
  },
  components: {
    Footer,
    HeaderMain
  },
  created() {
    setInterval(() => {
      this.currentTime = new Date();
    }, 60000); // Каждую минуту
  },
  computed: {
    futureTime() {
      // Создаем новый объект Date, добавляя 10 минут к текущему времени
      const futureTime = new Date(this.currentTime.getTime() + 10 * 60000); // 10 минут в миллисекундах

      // Форматируем время в формат hh:mm
      const hours = ('0' + futureTime.getHours()).slice(-2);
      const minutes = ('0' + futureTime.getMinutes()).slice(-2);

      // Возвращаем отформатированную строку времени
      return hours + ':' + minutes;
    }
  },
  methods: {
    renderTime() {
      // Get the current time and add 10 minutes to it
      var currentTime = new Date();
      var futureTime = new Date(currentTime.getTime() + 10 * 60 * 1000);
      // Format the time as HH:MM
      var formattedTime = futureTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      // Update each element with the class 'timeLabel' with the formatted time
      $('.timelabel').text(formattedTime);
    },
    changeDate(date) {
      var Year = date.getFullYear();
      var Month = date.getMonth();
      var Day = date.getDate();

      var fMonth;
      // Преобразуем месяца
      switch (Month) {
        case 0:
          fMonth = "января";
          break;
        case 1:
          fMonth = "февраля";
          break;
        case 2:
          fMonth = "марта";
          break;
        case 3:
          fMonth = "апреля";
          break;
        case 4:
          fMonth = "мая";
          break;
        case 5:
          fMonth = "июня";
          break;
        case 6:
          fMonth = "июля";
          break;
        case 7:
          fMonth = "августа";
          break;
        case 8:
          fMonth = "сентября";
          break;
        case 9:
          fMonth = "октября";
          break;
        case 10:
          fMonth = "ноября";
          break;
        case 11:
          fMonth = "декабря";
          break;
      }
      return `${Day} ${fMonth} ${Year}`;
    },
    jqueryLoaded() {
      // создание бегунков
      this.slider = document.getElementById("slider-sum"); // бегунок суммы
      this.slider2 = document.getElementById("slider-period"); // бегунок дней

      noUiSlider.create(this.slider, {
        start: 10000, // значение бегунка при загрузке
        connect: "lower",
        range: {
          min: 5000, // минимальное значение бегунка суммы
          max: 30000, // максимальное значение бегунка суммы
        },
        step:1000,
      });
      noUiSlider.create(this.slider2, {
        start: 91, // значение бегунка при загрузке
        connect: "lower",
        range: {
          min: 91, // минимальное значение бегунка дней
          max: 365, // максимальное значение бегунка дней
        },
      });

      // ивенты бегунков
      let sumBlock = document.getElementById("calculator_sum");
      let dateBlock = document.getElementById("calculator_date");
      let salePriceBlock = document.getElementById("sale-price");
      let priceBlock = document.getElementById("price");
      let dateFinishBlock = document.getElementById("date");

      this.slider.noUiSlider.on("slide", function (values, handle) {
        let dataValue = Math.round(values[handle]);
        // формула расчёта перечеркнутой суммы
        let salePrice = Math.round((dataValue * 0.1 + dataValue) * 1.1);
        sumBlock.innerHTML = `${dataValue.toLocaleString()}`;
        priceBlock.innerHTML = `${dataValue.toLocaleString()}`;
        salePriceBlock.innerHTML = `${salePrice}`;
      });

      this.slider2.noUiSlider.on("slide", (values, handle) => {
        let dataValue = Math.round(values[handle]);
        let date = new Date();
        date.setDate(date.getDate() + dataValue);
        dateBlock.innerHTML = `${dataValue}`;
        dateFinishBlock.innerHTML = this.changeDate(date);
      });
    },
    clickOpenLabel1() {
      window.localStorage.setItem('sum', this.slider.noUiSlider.get());
      window.localStorage.setItem('date', this.slider2.noUiSlider.get());

      let token = store.getters.AUTH_TOKEN;
      let tokenRefresh = store.getters.REFRESH_TOKEN;
      let uuid = store.getters.UUID;
      if (token && tokenRefresh && uuid && token !== 'undefined' && tokenRefresh !== 'undefined' && uuid !== 'undefined') {
        this.$store.commit('SET_HAVE_NEW_REQUEST', true);
      }
      this.$router.push('/personal-data');
    },
    reloadComponent() {
      this.$router.go(0);
    },
    goToLogin() {
      this.$router.push('/login');
    },
    goToReg() {
      this.$router.push('/registration-step1');
    },
    loadScripts() {
      // Удаляем старые скрипты
      this.removeOldScripts();

      // Подключаем jQuery
      loadjs(
          "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
          {
            success: () => {
              console.log('jQuery loaded');

              // Подключаем Webflow
              loadjs('./js/webflow.js', {
                success: () => {
                  console.log('Webflow loaded');
                  this.initAccordions();
                  this.jqueryLoaded();

                  // Call the function initially to set the time
                  this.renderTime();
                  // Set an interval to update the time every 10 minutes
                  setInterval(this.renderTime, 1000);
                },
                error: () => {
                  console.error('Failed to load Webflow');
                }
              });

              // Подключаем аккордеоны
              const scriptUrl = 'https://cdn.jsdelivr.net/npm/@finsweet/attributes-accordion@1/accordion.js';
              const script = document.createElement('script');
              script.async = true;
              script.src = scriptUrl;
              document.head.appendChild(script);
              script.onload = () => {
                console.log('Accordion script loaded');
                this.initAccordions();
              };
            },
            error: () => {
              console.error('Failed to load jQuery');
            }
          }
      );
    },
    removeOldScripts() {
      const scripts = document.querySelectorAll('script[src*="accordion.js"], script[src*="jquery-3.5.1.min.dc5e7f18c8.js"], script[src*="webflow.js"]');
      scripts.forEach(script => {
        script.parentNode.removeChild(script);
      });
    },

    initAccordions() {
      if (typeof window.fsAttributes !== 'undefined' && window.fsAttributes.accordion) {
        window.fsAttributes.accordion.init();
      } else {
        console.log('Accordion init function not found');
      }
    }
  },
  mounted() {
    this.loadScripts();
  },

  activated() {
    this.loadScripts();
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
</style>
